import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import Img from "gatsby-image";
import Layout from "../components/layout";
import Header from "../components/Header";
import Footer from "../components/footer.js";
import Carousel from "nuka-carousel";
import Text, { Section } from "../components/Theme";
import IntroSectionBackground from "../images/background__stories-1.jpg";
import chevronRight from '../images/icons/icon__chevron--white--right.svg';
// import { NewsletterSignup } from "../components/newsletter.js";

const IntroSection = styled.div`
    background-image: url(${IntroSectionBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0;
    color: var(--white);

    Section {
        min-height: calc(100vh - 100px);
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-flow: column-reverse wrap;
        align-items: baseline;
        padding-bottom: 10vh;

        h1 {

        }
    }
`;

const StandardGridSection = styled.div`
    padding: 0;
    color: var(--white);
`;

const SectionGrey = styled.div`
    background-color: var(--off-white);
`;

const Circle = styled.div`
    grid-column: 1/4;
    background-color: #000;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-top: 100%;
    border-radius: 1000px;
    position: relative;
`;

const InnerCircle = styled.div`
    background-color: white;
    width: 70%;
    height: 70%;
    border-radius: 1000px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const StandardGridTitle = styled(Text)`
    font-size: 25px;
    line-height: 1.3;
`;

const ShopCarousel = styled(Carousel)`
    width: 100%;
    grid-column: 1/13;
    grid-row: 3/4;
    align-items: start;
    justify-content: start;
    align-self: start;

    li {
        cursor: default;
    }

    .slider-frame {
        width: 100%;
    }

    .slider-slide {
        padding: 0 20px;
    }

    button {
        background: none;
    }
`;

const NewsletterContainer = styled.div`
    grid-column: 1/13;
    padding: 0;

    h3 {
        margin-top: 0;
    }
`;

const SubscribeBox = styled.div`
    // grid-column: 2/11;
    // padding: 50px 0 0 0;

    input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 0;
        width: 100%;
        border: 0px;
        background-color: #f1f1f1;
        background-repeat: no-repeat;
        background-position: 1.5rem center;
        height: 63px;
        font-size: 20px;
        padding: 1rem 2rem;
        grid-column: 1/13;

        &::placeholder {
            opacity: 0.3;
        }

        &[type="submit"] {
            border: 1px solid;
            background-size: 14px;
            background-position: calc(100% - 1rem) center;
            background-repeat: no-repeat;
            background-image: url(${chevronRight});
            background-color: var(--black);
            color: var(--white);
            width: 100%;
            min-width: 100px;
            max-width: 180px;
            text-align: left;
            margin-left: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 20px;
            height: 63px;
            font-size: 20px;
            line-height: 1;
        }
    }
`;

const NewsletterTitle = styled(Text)`
    margin-bottom: 20px;
    color: var(--black);
    font-family: var(--nhp);
    font-weight: 600;
    line-height: 1.2;
    width: 80%;

    a {
        display: block;
        font-size: 11px;
        padding-top: 20px;
        opacity: 0.4;

        &:hover {
            opacity: 1;
        }
    }
`;

const NewsletterParagraph = styled.p`
    color: var(--grey);
    font-family: var(--nhp);
    font-size: 18px;
    line-height: 1.2;
    margin: 20px 0;
`;

const SocialContainer = styled.div`
    padding: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;


const StoriesPage = ({ data }) => (

    <Layout>
        <div className="page-stories">
            <Header content_color="black" />

            <IntroSection>
                {/* TODO: REPLACE WITH CMS FIELDS */}
                <Section>
                    <div className="intro__content">
                        <Text ultra>
                            Stories
                        </Text>
                        <Text body>
                            Perspectives on our planet <br />
                            and how it's being impacted
                            <br />
                            <br />
                        </Text>
                    </div>
                </Section>
            </IntroSection>

            <Section large className="intro-text-section">
                {/* TODO: REPLACE WITH CMS FIELD */}
                <Text
                    sub
                    col="4/12"
                >
                    <p>Every once in a while, a story comes along that calls for more attention than a brief, daily post. Other times, we think the Overview perspective has something special to offer when focused on a particular topic. Here are some of those efforts, where we've gone into detail about what’s taking place on the planet.</p>
                </Text>
                <Circle
                    className="circle-image"
                    col="2/12"
                    style={{backgroundImage: "url(https://www.datocms-assets.com/12893/1604341786-belo-horizonte.jpg?auto=compress%2Cformat&dpr=1&fit=clip&w=900)"}}
                >
                    <InnerCircle />
                </Circle>
            </Section>

            <StandardGridSection className="standard-grid">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        {data.allDatoCmsStory.edges.slice(0, 3).map(({ node: story }, i) => (
                            <div className={i % 5 === 0 ? "cell small-12" : "cell small-12 medium-6"}>
                                <Link className="standard-grid__column" to={`/stories/${story.slug}`}>
                                    <Img className="standard-grid__image" fluid={story.featuredImage.fluid} />
                                    <div className="standard-grid__meta">
                                        <StandardGridTitle sub style={{ margin: 0 }}>
                                            {story.title}
                                        </StandardGridTitle>
                                        <Text
                                            caption
                                            style={{
                                                opacity: 0.5,
                                                margin: "10px 0 0 0",
                                                fontSize: "11px"
                                            }}
                                        >
                                            {story.meta.firstPublishedAt}
                                        </Text>
                                    </div>
                                    <Text
                                        body
                                        style={{
                                            opacity: 0.5,
                                            fontSize: "17px",
                                            lineHeight: "24px"
                                        }}
                                    >
                                        {story.subtitle}
                                    </Text>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </StandardGridSection>

            {/* TODO: REPLACE WITH COMPONENT VERSION OF NEWSLETTER SIGNUP */}
            {/*<NewsletterSignup />*/}
            <Section small className="subscribe-section subscribe-section__grey">
                <NewsletterContainer>
                    <div className="grid-container">
                        <div className="grid-x grid-padding-x">
                            <div className="cell small-12 medium-6">
                                {/* TODO: REPLACE WITH CMS FIELDS */}
                                <SubscribeBox>
                                    <NewsletterTitle sub>
                                        To receive our daily post and the occasional announcement:
                                    </NewsletterTitle>
                                    <form
                                        action="https://www.getrevue.co/profile/dailyoverview/add_subscriber"
                                        method="post"
                                        id="revue-form"
                                        name="revue-form"
                                        target="_blank"
                                        className="subscribe-section__form"
                                    >
                                        <input
                                            className="revue-form-field"
                                            placeholder="Enter your email"
                                            type="email"
                                            name="member[email]"
                                            id="member_email"
                                        />
                                        <input
                                            type="submit"
                                            value="Submit"
                                            name="member[subscribe]"
                                            id="member_submit"
                                            className="revue-form-submit"
                                        />
                                    </form>
                                    {/*<NewsletterParagraph>
                                        Subscribe to receive our best posts once a month
                                    </NewsletterParagraph>*/}
                                </SubscribeBox>
                            </div>
                            <div className="cell small-12 medium-6">
                                <h3>Follow Overview on Social</h3>
                                {/*<NewsletterParagraph>
                                    Join us on the social networks for the latest updates
                                </NewsletterParagraph>*/}
                                <SocialContainer>
                                    <a className="newsletter-social newsletter-social__facebook"
                                       href="https://www.facebook.com/doverview"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer1"
                                    >
                                        <img
                                            src={require("../images/icons/icon__facebook.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                    <a className="newsletter-social newsletter-social__twitter"
                                       href="https://twitter.com/DOverview"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer2"
                                    >
                                        <img
                                            src={require("../images/icons/icon__twitter.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                    <a className="newsletter-social newsletter-social__instagram"
                                       href="https://www.instagram.com/dailyoverview/"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer3"
                                    >
                                        <img
                                            src={require("../images/icons/icon__instagram.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                    <a className="newsletter-social newsletter-social__amazon"
                                       href="https://www.amazon.com/Benjamin-Grant/e/B01HMX7AZU/"
                                       target="_blank"
                                       rel="noopener noreferrer"
                                       key="SocialContainer4"
                                    >
                                        <img
                                            src={require("../images/icons/icon__amazon.svg")}
                                            width="24"
                                            alt="Daily Overview"
                                            className="grid-right"
                                        />
                                    </a>
                                </SocialContainer>
                            </div>
                        </div>
                    </div>
                </NewsletterContainer>
            </Section>

            <StandardGridSection className="standard-grid">
                <div className="grid-container">
                    <div className="grid-x grid-padding-x">
                        {data.allDatoCmsStory.edges.slice(3, data.allDatoCmsStory.edges.length).map(({ node: story }, i) => (
                            <div className={i % 5 === 0 ? "cell small-12" : "cell small-12 medium-6"}>
                                <Link className="standard-grid__column" to={`/stories/${story.slug}`}>
                                    <Img className="standard-grid__image" fluid={story.featuredImage.fluid} />
                                    <div className="standard-grid__meta">
                                        <StandardGridTitle sub style={{ margin: 0 }}>
                                            {story.title}
                                        </StandardGridTitle>
                                        <Text
                                            caption
                                            style={{
                                                opacity: 0.5,
                                                margin: "10px 0 0 0",
                                                fontSize: "11px"
                                            }}
                                        >
                                            {story.meta.firstPublishedAt}
                                        </Text>
                                    </div>
                                    <Text
                                        body
                                        style={{
                                            opacity: 0.5,
                                            fontSize: "17px",
                                            lineHeight: "24px"
                                        }}
                                    >
                                        {story.subtitle}
                                    </Text>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </StandardGridSection>

            {/* TODO: REPLACE WITH CMS FIELDS */}
            {/*<SectionGrey>
                <Section small className="shop-slider__intro">
                    <Text
                        sub
                        col="1/5"
                        mobileCol="1/13"
                    >
                        <img
                            src={require("../images/icons/icon__cart.svg")}
                            alt="daily overview"
                            className="image-icon__large"
                        />
                        <h2 className="section-title">Shop</h2>
                    </Text>
                    <Text
                        sub
                        col="5/13"
                        mobileCol="1/13"
                    >
                        <p className="section-lead-paragraph">Through our daily posts, products, and collaborations we aim to inspire the Overview Effect.</p>
                    </Text>
                </Section>

                <Section small className="shop-slider">

                    <ShopCarousel
                        width="100%"
                        transitionMode="scroll"
                        autoplayInterval={3000}
                        pauseOnHover
                        wrapAround
                        renderBottomCenterControls={false}
                        slidesToScroll={1}
                        slidesToShow={4}
                        slideWidth={1.0}
                        initialSlidewidth={1.0}
                        autoplay
                        renderCenterLeftControls={({ previousSlide }) => (
                            <button onClick={previousSlide}>
                                <img
                                    src={require("../images/icons/icon__chevron--left.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                            </button>
                        )}
                        renderCenterRightControls={({ nextSlide }) => (
                            <button onClick={nextSlide}>
                                <img
                                    src={require("../images/icons/icon__chevron--right.svg")}
                                    alt="daily overview"
                                    className="image-icon__large"
                                />
                            </button>
                        )}
                    >
                        <div className="shop-slider__slide">
                            <div className="onebytwo">
                                <img alt="daily-overview" src="https://www.datocms-assets.com/12893/1560934561-venice.jpg?auto=compress%2Cformat&dpr=1&fit=clip&w=900" />
                            </div>
                            <h4>Ardar Plataeu</h4>
                        </div>
                        <div className="shop-slider__slide">
                            <div className="onebytwo">
                                <img alt="daily-overview" src="https://www.datocms-assets.com/12893/1560980266-boatoninglisriver.jpg?auto=compress%2Cformat&dpr=1&fit=clip&w=900" />
                            </div>
                            <h4>Ardar Plataeu</h4>
                        </div>
                        <div className="shop-slider__slide">
                            <div className="onebytwo">
                                <img alt="daily-overview" src="https://www.datocms-assets.com/12893/1570416821-image-asset.jpeg?auto=compress%2Cformat&dpr=1&fit=clip&w=900" />
                            </div>
                            <h4>Ardar Plataeu</h4>
                        </div>
                        <div className="shop-slider__slide">
                            <div className="onebytwo">
                                <img alt="daily-overview" src="https://www.datocms-assets.com/12893/1560996966-denverinternationalairport.jpg?auto=compress%2Cformat&dpr=1&fit=clip&w=900" />
                            </div>
                            <h4>Ardar Plataeu</h4>
                        </div>
                        <div className="shop-slider__slide">
                            <div className="onebytwo">
                                <img alt="daily-overview" src="https://www.datocms-assets.com/12893/1561001529-gibraltarinternationalairportgib.jpg?auto=compress%2Cformat&dpr=1&fit=clip&w=900" />
                            </div>
                            <h4>Ardar Plataeu</h4>
                        </div>
                        <div className="shop-slider__slide">
                            <div className="onebytwo">
                                <img alt="daily-overview" src="https://www.datocms-assets.com/12893/1561001321-incheonbridge.jpg?auto=compress%2Cformat&dpr=1&fit=clip&w=900" />
                            </div>
                            <h4>Incheon Bridge</h4>
                        </div>
                    </ShopCarousel>
                </Section>
            </SectionGrey>*/}

        </div>
        <Footer />
    </Layout>
);

export default StoriesPage;

export const query = graphql`
           query StoryQuery {
               allDatoCmsStory(
                   sort: { fields: meta___firstPublishedAt, order: DESC }
               ) {
                   edges {
                       node {
                           title
                           subtitle
                           meta {
                               firstPublishedAt(formatString: "MM/DD/YYYY")
                           }

                           slug
                           featuredImage {
                               fluid(
                                   imgixParams: {
                                       h: "400"
                                       w: "600"
                                       fit: "crop"
                                   }
                               ) {
                                   ...GatsbyDatoCmsFluid
                               }
                           }
                       }
                   }
               }
           }
       `;
